import React, {useContext, useEffect} from 'react';
import 'react-toastify/dist/ReactToastify.css';
import {Bounce, ToastContainer} from "react-toastify";
import './App.css';

import LandingPageScreen from "./screens/landing-page/LandingPageScreen";
import {AppContext} from "./context/AppContext";
import {Web3ServiceIns} from "./service/Web3Service";

import {createAppKit, useAppKitProvider, useAppKitAccount} from '@reown/appkit/react'
import { EthersAdapter } from '@reown/appkit-adapter-ethers'
import { bsc, bscTestnet, solana, AppKitNetwork } from '@reown/appkit/networks'
import {WebConfigV2} from "./config/web.config.v2";

const projectId = WebConfigV2.WalletConnectInfo.project_id || '';

const networks = [bsc, bscTestnet, solana] as [AppKitNetwork, ...AppKitNetwork[]];

const metadata = WebConfigV2.WalletConnectInfo;

createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    email: false,
    socials: [],
    emailShowWallets: false,
    onramp: false,
    swaps: false,
  },
  themeVariables: {
    '--w3m-accent': '#b68c00',
  }
});

function App() {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error("App context not found");
  }
  
  const {address, isConnected} = useAppKitAccount();
  const { walletProvider } = useAppKitProvider('eip155');
  
  useEffect(() => {
    Web3ServiceIns.setContext(appContext);
    Web3ServiceIns.loadTokenData();
  }, []);
  
  useEffect(() => {
    if (isConnected) {
      Web3ServiceIns.setWalletAddress(address);
    } else {
      Web3ServiceIns.setWalletAddress(undefined);
    }
  }, [isConnected, address]);
  
  useEffect(() => {
    Web3ServiceIns.setWalletProvider(walletProvider);
  }, [walletProvider]);
  
  return (
    <>
      <LandingPageScreen />
      <ToastContainer
        position="bottom-right"
        autoClose={3000}
        closeOnClick
        pauseOnFocusLoss={false}
        draggable
        transition={Bounce}
        theme="dark"
      />
    </>
  );
}

export default App;
