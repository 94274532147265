import React from "react";
import styled from "styled-components";
import { WebConfigV2 } from "../../../../config/web.config.v2";

const BNBStakingHeader: React.FC = () => {
  return (
    <Section>
      <Container>
        <HeaderContainer>
          <LeftSection>
            <Title>{WebConfigV2.Content.staking_info_section.title}</Title>
            <Subtitle>{WebConfigV2.Content.staking_info_section.subtitle}</Subtitle>
            <a href="#action">
              <StakeButton>{WebConfigV2.Content.staking_info_section.button}</StakeButton>
            </a>
          </LeftSection>
          
          <RightSection>
            <APRContainer>
              <APRValue>{WebConfigV2.Content.staking_info_section.highlight_text}</APRValue>
              <APRLabel>{WebConfigV2.Content.staking_info_section.sub_text}</APRLabel>
            </APRContainer>
            <APRDescription>{WebConfigV2.Content.staking_info_section.desc}</APRDescription>
          </RightSection>
        </HeaderContainer>
      </Container>
    </Section>
  );
};

const HeaderWrapper = styled.div`  
  width: 100%;  
  margin-top: 40px;  
  padding: 0 max(8%, calc((100% - 1200px) / 2));

  @media (max-width: 1536px) {  
    padding: 0 8%;  
  }  

  @media (max-width: 1280px) {  
    padding: 0 6%;  
  }  

  @media (max-width: 1024px) {  
    padding: 0 4%;  
  }  

  @media (max-width: 768px) {  
    padding: 0 16px;  
  }  
`;  

const HeaderContainer = styled.div`  
  display: flex;  
  justify-content: space-between;  
  align-items: center;  
  color: white;  
  width: 100%;  
  margin: 0 auto;
  gap: clamp(1.5rem, 3vw, 2rem);  
  position: relative;  
  padding: clamp(2rem, 5vw, 4rem);  

  background: linear-gradient(
    135deg,
    rgba(255, 255, 255, 0.15),
    rgba(99, 102, 241, 0.3),
    rgba(99, 102, 241, 0.15),
    rgba(255, 255, 255, 0.1)
  );

  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: clamp(16px, 2vw, 24px);
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 255, 255, 0.1),
      rgba(99, 102, 241, 0.2),
      rgba(99, 102, 241, 0.1),
      rgba(255, 255, 255, 0.05)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &::after {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: clamp(16px, 2vw, 24px);
    box-shadow: inset 0 1px 1px rgba(255, 255, 255, 0.1);
    pointer-events: none;
  }

  box-shadow: 0 0 30px rgba(99, 102, 241, 0.15),
    inset 0 0 50px rgba(99, 102, 241, 0.08);
  border-radius: clamp(16px, 2vw, 24px);
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

const LeftSection = styled.div`
  flex: 1;
  position: relative;
  padding-right: clamp(1.5rem, 3vw, 3rem);

  &::after {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 1px;
    height: 80%;
    background: linear-gradient(
      to bottom,
      transparent,
      rgba(99, 102, 241, 0.2),
      transparent
    );
  }

  @media (max-width: 768px) {
    padding-right: 0;
    padding-bottom: 2rem;

    &::after {
      display: none;
    }
  }
`;

const Title = styled.h2`
  font-size: 64px;
  font-weight: 600;
  margin-bottom: clamp(1rem, 2vw, 1.5rem);
  text-shadow: 
    0 0 7px rgba(255, 215, 0, 0.3),
    0 0 10px rgba(255, 215, 0, 0.2);

  background: linear-gradient(to right, #FFD700, #FFA500);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;  

  @keyframes shine {  
    from {  
      background-position: -100% center;  
    }  
    to {  
      background-position: 100% center;  
    }  
  } 

  @media (max-width: 1200px) {
    font-size: 48px;
    margin-bottom: 60px;
  };
`;

const Subtitle = styled.p`
  color: #94a3b8;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  margin-bottom: clamp(1.5rem, 3vw, 2.5rem);
`;

const StakeButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  color: #000;
  padding: clamp(0.75rem, 1vw, 1rem) clamp(1.5rem, 2vw, 2.5rem);
  border-radius: 0.75rem;
  font-weight: 600;
  font-size: clamp(1rem, 1.25vw, 1.25rem);
  border: none;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: -1px;
    border-radius: 0.75rem;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 215, 0, 0.3),
      rgba(255, 215, 0, 0.1)
    );
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 0 7px #FFD700,
      0 0 10px #FFD700,
      0 0 21px #FFD700,
      0 0 42px rgb(255, 215, 0);
  }
`;

const RightSection = styled.div`
  text-align: right;
  min-width: min(380px, 100%);
  padding-left: clamp(1.5rem, 3vw, 3rem);

  @media (max-width: 768px) {
    padding-left: 0;
    padding-top: 2rem;
    width: 100%;
  }
`;

const APRContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: clamp(0.5rem, 1vw, 0.75rem);
  margin-bottom: clamp(0.5rem, 1vw, 0.75rem);
`;

const APRValue = styled.div`
  font-size: clamp(2.5rem, 5vw, 5rem);
  font-weight: bold;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 1;
  text-shadow: 
    0 0 7px rgba(255, 215, 0, 0.3),
    0 0 10px rgba(255, 215, 0, 0.2);
`;

const APRLabel = styled.div`
  font-size: clamp(1rem, 1.25vw, 1.25rem);
  color: #94a3b8;
  margin-top: clamp(0.5rem, 1vw, 0.75rem);
`;

const APRDescription = styled.div`
  font-size: clamp(0.875rem, 1vw, 1rem);
  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.2);
`;

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding: 80px 8%;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.85)),
      url('/images/mining-Loan.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.4;
    z-index: -2;
  }

  /* Thêm hiệu ứng ánh sáng và máy đào */
  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: 
      radial-gradient(circle at 20% 30%, rgba(255, 215, 0, 0.15) 0%, transparent 40%),
      radial-gradient(circle at 80% 70%, rgba(255, 215, 0, 0.15) 0%, transparent 40%);
    z-index: -1;
    animation: glow 4s ease-in-out infinite alternate;
  }

  @keyframes glow {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 0.7;
    }
  }
`;

const Container = styled.div`
  position: relative;
  max-width: 1200px;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(10px);
  border-radius: 24px;
  border: 1px solid rgba(255, 215, 0, 0.1);
  padding: 32px;
  z-index: 1;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 1px;
    background: linear-gradient(
      to bottom right,
      rgba(255, 215, 0, 0.3),
      transparent 50%
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`;

export default BNBStakingHeader;
