import React, {useContext, useEffect, useState} from 'react';
import styled from 'styled-components';
import {AppContext} from "../../../../context/AppContext";
import {useAppKitAccount} from "@reown/appkit/react";
import {
  showProcessingToast,
  showSuccessToast,
  updateErrorToast,
  updateSuccessToast
} from "../../../../helper/ToastHelper";
import {Web3ServiceIns} from "../../../../service/Web3Service";
import {NumberFormatter} from "../../../../helper/number.helper";
import {BREAK_POINT_MEDIUM, BREAK_POINT_SMALL} from "../../../constants/constants";
import {WebConfigV2} from "../../../../config/web.config.v2";
import { SectionTitle } from '../styles/CommonStyles';

const ReferralSectionV2: React.FC<{
  id: string;
}> = ({
        id
      }) => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error();
  }
  
  const {address} = useAppKitAccount();
  
  const {refInfo} = appContext;
  
  const [refLink, setRefLink] = useState<string>('');
  
  useEffect(() => {
    if (address) {
      setRefLink(`${window.location.origin}/?ref=${address}`);
    } else {
      setRefLink(WebConfigV2.Content.referral_section.referral_info.referral_link_placeholder);
    }
  }, [address]);
  
  const handleCopy = async () => {
    if (!address) {
      return;
    }
    
    await navigator.clipboard.writeText(refLink);
    showSuccessToast("", "Copied referral link");
  }
  
  const handleWithdraw = async () => {
    try {
      showProcessingToast('withdraw_ref', WebConfigV2.Content.toast.withdraw.processing);
      await Web3ServiceIns.withdrawRef();
      updateSuccessToast('withdraw_ref', WebConfigV2.Content.toast.withdraw.success);
    } catch (e: any) {
      updateErrorToast('withdraw_ref', WebConfigV2.Content.toast.withdraw.error + e.reason);
    }
  }
  
  return (
    <Container id={id}>
      <SectionTitle>Referral Program</SectionTitle>
      <ContentContainer>
        <ReferralInfoContainer>
          <ReferralLinkArea>
            <ReferralLinkInput 
              value={refLink} 
              readOnly
              placeholder="You will get your ref link after investing"
            />
            <CopyButton onClick={handleCopy}>Copy Link</CopyButton>
          </ReferralLinkArea>

          <ReferralStatsGrid>
            <ReferralStatItem>
              <StatLabel>Referral Earned Available</StatLabel>
              <StatValue>{`${refInfo.earnAvailable} BNB`}</StatValue>
            </ReferralStatItem>
            
            <ReferralStatItem>
              <StatLabel>Total Referral Earned</StatLabel>
              <StatValue>{`${refInfo.totalEarned} BNB`}</StatValue>
            </ReferralStatItem>
            
            <ReferralStatItem>
              <StatLabel>Total Referral Withdraw</StatLabel>
              <StatValue>{`${refInfo.totalWithdraw} BNB`}</StatValue>
            </ReferralStatItem>
            
            <ReferralStatItem>
              <StatLabel>Total Referrals</StatLabel>
              <StatValue>{refInfo.totalReferral}</StatValue>
            </ReferralStatItem>
          </ReferralStatsGrid>

          <WithdrawButton onClick={handleWithdraw}>Withdraw referral rewards</WithdrawButton>
        </ReferralInfoContainer>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.section`  
  position: relative; 
  display: flex;  
  align-items: center;  
  justify-content: center;  
  width: 100%;  
  padding: 4% 8%;  

  background: linear-gradient(180deg, 
    rgba(0, 0, 0, 0) 0%,
    rgba(167, 139, 250, 0.05) 50%,
    rgba(0, 0, 0, 0) 100%
  );

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      1000px circle at 80% 50%,
      rgba(167, 139, 250, 0.08),
      transparent 60%
    );
    pointer-events: none;
  }
`;

const ContentContainer = styled.div`
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
`;

const ReferralInfoContainer = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 32px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
`;

const ReferralLinkArea = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
`;

const ReferralLinkInput = styled.input`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 100%;
  outline: none;
  opacity: 0.8;
  word-wrap: anywhere;
  
  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 12px;
  }
`;

const CopyButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 8px 16px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  font-weight: 600;
  white-space: nowrap;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 0 7px #FFD700,
      0 0 10px #FFD700,
      0 0 21px #FFD700;
  }

  &:active {
    transform: translateY(0);
  }

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 8px;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 215, 0, 0.3),
      rgba(255, 215, 0, 0.1)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }
`;

const ReferralStatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const ReferralStatItem = styled.div`
  background: rgba(255, 255, 255, 0.02);
  padding: 24px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StatLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const StatValue = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;
  
  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 18px;
  }
`;

const WithdrawButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 16px;
  border-radius: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 0.75rem;
    padding: 1px;
    background: linear-gradient(
      135deg,
      rgba(255, 215, 0, 0.3),
      rgba(255, 215, 0, 0.1)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 0 7px #FFD700,
      0 0 10px #FFD700,
      0 0 21px #FFD700;
  }

  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 14px;
  }
`;

export default ReferralSectionV2;