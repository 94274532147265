import {toast} from "react-toastify";
import React from "react";

export const showProcessingToast = (toastId: string, message: string) => {
  toast.info(message, {
    toastId: toastId,
    isLoading: true
  });
}

export const showSuccessToast = (toastId: string, message: string) => {
  toast.success(message, {
    toastId: toastId
  });
}

export const showErrorToast = (toastId: string, message: string) => {
  toast.error(message, {
    toastId: toastId
  });
}

export const updateSuccessToast = (toastId: string, message: string) => {
  toast.update(toastId, {
    type: 'success',
    isLoading: false,
    autoClose: 3000,
    hideProgressBar: false,
    render: <span>{message}</span>
  });
}

export const updateErrorToast = (toastId: string, message: string) => {
  toast.update(toastId, {
    type: 'error',
    isLoading: false,
    autoClose: 3000,
    hideProgressBar: false,
    render: <span>{message}</span>
  });
}