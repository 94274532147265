import styled from 'styled-components';
import { BREAK_POINT_MEDIUM } from '../../../constants/constants';

export const SectionTitle = styled.h2`
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 80px;
  background: linear-gradient(to bottom, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0 0 7px rgba(255, 215, 0, 0.3),
    0 0 10px rgba(255, 215, 0, 0.2);

  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 48px;
    margin-bottom: 60px;
  }
`; 