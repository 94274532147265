import React from 'react';
import styled, { keyframes } from 'styled-components';

const slideText = keyframes`
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
`;

const Footer: React.FC = () => {
  return (
    <FooterContainer>
      <FooterText>
        Copyright@ExtremeBTC - Please review the cryptocurrency mining disclaimer carefully.
      </FooterText>
      <DisclaimerText>
        An investment in Digital Assets carries significant risk. The value of an investment and any returns can go up or down.
      </DisclaimerText>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  width: 100%;
  padding: 24px 0;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow: hidden;
`;

const FooterText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0 0 10px rgba(255, 215, 0, 0.3),
    0 0 20px rgba(255, 215, 0, 0.2);
  white-space: nowrap;
  animation: ${slideText} 15s linear infinite;
  position: relative;
  width: fit-content;
  margin: 0 auto;

  &:hover {
    text-shadow: 
      0 0 15px rgba(255, 215, 0, 0.4),
      0 0 30px rgba(255, 215, 0, 0.3);
    animation-play-state: paused;
  }
`;

const DisclaimerText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.6;
  color: #a78bfa;
  max-width: 800px;
  margin: 0 auto;
  padding: 0 20px;
  opacity: 0.9;
  text-shadow: 0 0 10px rgba(167, 139, 250, 0.3);
  transition: all 0.3s ease;
  letter-spacing: 0.5px;

  &:hover {
    opacity: 1;
    text-shadow: 
      0 0 15px rgba(167, 139, 250, 0.4),
      0 0 30px rgba(167, 139, 250, 0.3);
  }
`;

export default Footer; 