import React, {ChangeEvent, useContext, useEffect} from "react";
import styled from "styled-components";
import {AppContext} from "../../../../../context/AppContext";
import {formatTime} from "../../../../../helper/time.helper";
import {showProcessingToast, updateErrorToast, updateSuccessToast} from "../../../../../helper/ToastHelper";
import {Web3ServiceIns} from "../../../../../service/Web3Service";
import {NumberFormatter} from "../../../../../helper/number.helper";
import {BREAK_POINT_SMALL} from "../../../../constants/constants";
import {WebConfigV2} from "../../../../../config/web.config.v2";

export const SellComponent: React.FC = () => {
  const appContext = useContext(AppContext);
  if (!appContext) {
    throw new Error();
  }
  
  const {minningInfo} = appContext;
  
  const [resetIn, setResetIn] = React.useState<string>('00:00:00');
  const [resetTimeout, setResetTimeout] = React.useState<NodeJS.Timeout | undefined>(undefined);
  const [sellValue, setSellValue] = React.useState<string>('0.0');
  const [receiveBNB, setReceiveBNB] = React.useState<string>('0.00');
  
  useEffect(() => {
    if (resetTimeout) {
      clearTimeout(resetTimeout);
    }
    
    handleCheckResetTime(minningInfo.timeReset);
  }, [minningInfo]);
  
  useEffect(() => {
    const fetchData = async () => {
      const _receiveBNB = await Web3ServiceIns.convertBNBAmount(sellValue);
      setReceiveBNB(_receiveBNB);
    }
    fetchData();
  }, [sellValue]);
  
  const handleCheckResetTime = (timeSeconds: number) => {
    const timeFormat = formatTime(timeSeconds);
    setResetIn(timeFormat);
    if (timeSeconds > 0) {
      const nResetTimeout = setTimeout(handleCheckResetTime.bind(null, timeSeconds - 1), 1000);
      setResetTimeout(nResetTimeout);
    }
  }
  
  const handleChangeSellValue = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    setSellValue(value);
  }
  
  const handleClickMax = () => {
    setSellValue(minningInfo.userTokenBalance);
  }
  
  const handleSellToken = async () => {
    try {
      showProcessingToast('sell_t', WebConfigV2.Content.toast.sell_token.processing);
      await Web3ServiceIns.sellToken(parseFloat(sellValue));
      updateSuccessToast('sell_t', WebConfigV2.Content.toast.sell_token.success);
      setSellValue('0');
    } catch (e: any) {
      updateErrorToast('sell_t', WebConfigV2.Content.toast.sell_token.error + e.reason);
    }
  }
  
  return (
    <>
      <StatsGrid>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_sell.sold_today_title}</StatLabel>
          <StatValue>{`${minningInfo.soldToday} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_sell.available_today_title}</StatLabel>
          <StatValue>{`${minningInfo.availableToday} ${WebConfigV2.ProjectInfo.token_symbol}`}</StatValue>
        </StatItem>
        <StatItem>
          <StatLabel>{WebConfigV2.Content.action_section.action_sell.reset_in_title}</StatLabel>
          <StatValue>{resetIn}</StatValue>
        </StatItem>
      </StatsGrid>
      
      <InputGroup>
        <InputLabel>
          <span>{WebConfigV2.Content.action_section.action_sell.sell_amount_title}</span>
          <span>{`Balance: ${minningInfo.userTokenBalance} ${WebConfigV2.ProjectInfo.token_symbol}`}</span>
        </InputLabel>
        <Input>
          <TokenInput
            placeholder="0.0"
            type="number"
            value={sellValue}
            onChange={handleChangeSellValue}
          />
          <MaxButton onClick={handleClickMax}>MAX</MaxButton>
        </Input>
      </InputGroup>
      
      <ReceiveInfo>
        <ReceiveLabel>{WebConfigV2.Content.action_section.action_sell.will_receive_title}</ReceiveLabel>
        <ReceiveValue>{receiveBNB} BNB</ReceiveValue>
      </ReceiveInfo>
      
      <ActionButton onClick={handleSellToken}>{WebConfigV2.Content.action_section.action_sell.sell_button}</ActionButton>
    </>
  )
}

const StatsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-bottom: 32px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }
`;

const StatItem = styled.div`
  //background: rgba(255, 255, 255, 0.02);
  padding: 16px;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 8px;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

const StatLabel = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
`;

const StatValue = styled.div`
  color: #ffffff;
  font-size: 24px;
  font-weight: 600;

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 18px;
  };
`;

const InputGroup = styled.div`
  margin-bottom: 24px;
`;

const InputLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
`;

const Input = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
`;

const TokenInput = styled.input`
  background: transparent;
  border: none;
  color: #ffffff;
  font-size: 18px;
  outline: none;
  width: 100%;

  &::placeholder {
    color: rgba(255, 255, 255, 0.3);
  }
`;

const MaxButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 8px 16px;
  border-radius: 0.75rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: rgba(255, 215, 0, 0.8);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }
`;

const ReceiveInfo = styled.div`
  background: rgba(255, 255, 255, 0.02);
  border-radius: 16px;
  padding: 16px;
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ReceiveLabel = styled.div`
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
`;

const ReceiveValue = styled.div`
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
`;

const ActionButton = styled.button`
  background: linear-gradient(to top, #FFD700, #FFED4A);
  border: none;
  color: #000;
  padding: 16px;
  border-radius: 0.75rem;
  font-size: 18px;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(255, 215, 0, 0.3);
  }

  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    font-size: 14px;
  };
`;