import React from "react";
import styled from "styled-components";
import {NavBar} from "./components/v2/NavBar";
import {RightBar} from "./components/v2/RightBar";
import useSmoothScroll from "../hooks/useSmoothScroll";
import {BannerSectionV2} from "./components/v2/BannerSectionV2";
import TokenInfoSection from "./components/v2/TokenInfoSection";
import StakingInfoSection from "./components/v2/StakingInfoSection";
import KeyFeatureSection from "./components/v2/KeyFeatureSection";
import PoolStatisticSection from "./components/v2/PoolStatisticSection";
import ActionSectionV2 from "./components/v2/ActionSectionV2";
import ReferralSectionV2 from "./components/v2/ReferralSectionV2";
import PartnerSectionV2 from "./components/v2/PartnerSectionV2";
import Footer from './components/v2/Footer';

const LandingPageScreen: React.FC = () => {
  useSmoothScroll();
  
  const [showRightBar, setShowRightBar] = React.useState(false);
  
  return (
    <Container>
      {/* Ẩn BannerSection có id="home" */}
      {/* <BannerSectionV2 id="home" /> */}
      <PaddingContainer>
        <TokenInfoSection />
        <StakingInfoSection />
        <KeyFeatureSection id="quick-guide"/>
        <PoolStatisticSection />
        <ActionSectionV2 id="action"/>
        <ReferralSectionV2 id="referral" />
        <PartnerSectionV2 />
      </PaddingContainer>
      
      <NavBarArea>
        <NavBar
          onClickMoreButton={() => setShowRightBar(!showRightBar)}
        />
      </NavBarArea>
      
      {showRightBar && (
        <RightBar onHide={() => setShowRightBar(false)} />
      )}
      <Footer />
    </Container>
  )
}

export default LandingPageScreen;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #0e172a;
  align-items: center;
  gap: 120px;
  font-family: 'Inter Tight', 'Inter', Arial, sans-serif;
`

const PaddingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 150px;
  box-sizing: border-box;
  padding-top: 100px;
`

const NavBarArea = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  z-index: 100;
`