import React from 'react';  
import styled from 'styled-components';  
import { WebConfigV2 } from "../../../../config/web.config.v2";  

interface NavBarProps {  
  onClickMoreButton?: () => void;  // Made optional since we're removing the button  
}  

export const NavBar: React.FC<NavBarProps> = () => {  
  return (  
    <Container>  
      <FirstLineContainer>  
        <LogoSection>  
          <AppLogo src={WebConfigV2.Content.nav_section.app_logo} alt="Logo" />  
          <NavTitleArea>  
            <NavTitle href="#home">  
              {WebConfigV2.Content.nav_section.home_title}  
            </NavTitle>  
            <NavTitle href="#action">  
              {WebConfigV2.Content.nav_section.stake_title}  
            </NavTitle>  
            <NavTitle href="http://v1.extremebtc.io">  
              {WebConfigV2.Content.nav_section.referral_title}  
            </NavTitle>  
            <NavTitle   
              href={WebConfigV2.Content.nav_section.document_link}   
              target="_blank"   
              rel="noopener noreferrer"  
            >  
              {WebConfigV2.Content.nav_section.document_title}  
            </NavTitle>  
          </NavTitleArea>  
        </LogoSection>  

        <RightButtonArea>
          <BlockChainButtonArea className="wallet-connect-wrapper">
            <appkit-button/>
          </BlockChainButtonArea>
        </RightButtonArea>  
      </FirstLineContainer>  
    </Container>  
  );  
};

// Styled Components  
const Container = styled.nav`  
  position: relative;  
  width: calc(100% - 32px);  
  max-width: 1400px;  
  height: 72px;
  margin: 16px auto 0;
`;

const FirstLineContainer = styled.div`  
  display: flex;  
  width: 100%;  
  height: 100%;
  padding: 0 24px;
  box-sizing: border-box;  
  align-items: center;  
  justify-content: space-between;  
  gap: 16px;  
  background: transparent;

  @media (max-width: 768px) {  
    padding: 0 16px;  
  }  
`;

const LogoSection = styled.div`  
  display: flex;  
  align-items: center;  
  gap: 64px;  
  background: transparent;

  @media (max-width: 1120px) {  
    gap: 32px;
  }  

  @media (max-width: 768px) {  
    gap: 16px;
  }  
`;

const NavTitleArea = styled.div`  
  display: flex;  
  gap: 32px;  
  height: 100%;  
  align-items: center;  
  background: transparent;

  @media (max-width: 1120px) {  
    gap: 16px;
  }  

  @media (max-width: 900px) {  
    display: none;
  }  
`;

const NavTitle = styled.a`  
  font-weight: 500;  
  font-size: 0.95rem;  
  text-decoration: none;  
  color: rgba(255, 255, 255, 0.7);  
  padding: 6px 12px;  
  border-radius: 6px;  
  transition: all 0.3s ease;  
  white-space: nowrap;
  letter-spacing: 0.5px;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &:hover {  
    color: #000;
    background: linear-gradient(to top, #FFD700, #FFD700);
    box-shadow: 
      0 0 7px #FFD700,
      0 0 10px #FFD700,
      0 0 21px #FFD700,
      0 0 42px rgb(255, 215, 0);
    font-weight: 700;
    text-shadow: 0 0 3px rgba(255, 255, 255, 0.5);
  }  

  @media (max-width: 1120px) {  
    padding: 4px 8px;
    font-size: 0.9rem;  
  }  
`

const AppLogo = styled.img`  
  height: 40px;  
  object-fit: contain;  
  transition: opacity 0.2s ease;  

  &:hover {  
    opacity: 0.9;  
  }  

  @media (max-width: 500px) {  
    height: 32px;  
  }  
`;

const RightButtonArea = styled.div`  
  display: flex;  
  align-items: center;  
`;

const BlockChainButtonArea = styled.div`
  w3m-button {
    --w3m-accent-color: #FFD700 !important;
    --w3m-accent-fill-color: #FFD700 !important;
    --w3m-text-big-bold-color: #FFD700 !important;
    --w3m-color-fg-1: #FFD700 !important;
    --w3m-color-fg-2: #FFD700 !important;
    --w3m-color-fg-3: #FFD700 !important;

    button {
      background: transparent !important;
      color: #FFD700 !important;
      border: 1px solid #FFD700 !important;
      border-radius: 8px !important;
      padding: 8px 16px !important;
      transition: all 0.3s ease !important;

      &:hover {
        background: rgba(255, 215, 0, 0.1) !important;
        box-shadow:
          0 0 7px #FFD700,
          0 0 10px #FFD700,
          0 0 21px #FFD700;
      }
    }
  }
`;

// const BlockChainButtonArea = styled.div`
//   display: flex;
//   transition: all 0.3s ease;
//
//   :where(w3m-button) {
//     --w3m-accent-color: #FFD700;
//     --w3m-accent-fill-color: #FFD700;
//     --w3m-background-color: #FFD700;
//     --w3m-button-border-radius: 8px;
//
//     --w3m-accent: #FFD700;
//     --w3m-color-mix: #FFD700;
//     --w3m-border-radius: 0.75rem;
//     --w3m-background-color: transparent !important;
//     --w3m-background-border-radius: 0.75rem;
//     --w3m-container-color: #fff;
//     --w3m-button-hover-bg-color: transparent !important;
//     --w3m-overlay-background-color: transparent !important;
//     --w3m-overlay-backdrop-filter: none !important;
//     --w3m-button-border: none !important;
//     --w3m-background: transparent !important;
//     --w3m-background-image: none !important;
//     --w3m-color-bg-1: transparent !important;
//     --w3m-color-bg-2: transparent !important;
//     --w3m-color-bg-3: transparent !important;
//     --w3m-z-index: 1 !important;
//     --w3m-secondary-button-border-radius: 0.75rem !important;
//     --w3m-secondary-button-background: transparent !important;
//
//     /* Thêm các variables mới từ DevTools */
//     --w3m-background-border-radius: 0.75rem !important;
//     --w3m-container-color: #fff !important;
//     --w3m-button-hover-bg-color: transparent !important;
//     --w3m-overlay-background-color: transparent !important;
//     --w3m-overlay-backdrop-filter: none !important;
//     --w3m-button-border: none !important;
//     --w3m-background: transparent !important;
//     --w3m-background-image: none !important;
//     --w3m-color-bg-1: transparent !important;
//     --w3m-color-bg-2: transparent !important;
//     --w3m-color-bg-3: transparent !important;
//     --w3m-z-index: 1 !important;
//     --w3m-secondary-button-border-radius: 0.75rem !important;
//     --w3m-secondary-button-background: transparent !important;
//     --w3m-wallet-icon-border-radius: 0.75rem !important;
//     --w3m-wallet-icon-large-border-radius: 0.75rem !important;
//     --w3m-wallet-icon-small-border-radius: 0.75rem !important;
//     --w3m-input-border-radius: 0.75rem !important;
//     --w3m-notification-border-radius: 0.75rem !important;
//     --w3m-notification-message-border-radius: 0.75rem !important;
//     --w3m-background-border-radius: 0.75rem !important;
//
//     transition: all 0.3s ease;
//
//     &:hover {
//       transform: translateY(-2px);
//       box-shadow:
//         0 0 7px #FFD700,
//         0 0 10px #FFD700,
//         0 0 21px #FFD700,
//         0 0 42px rgb(255, 215, 0);
//       color: #FFD700;
//       --w3m-text-big-bold: #FFD700 !important;
//     }
//   }
// `;

// Type declaration for w3m-button  
declare global {  
  namespace JSX {  
    interface IntrinsicElements {  
      'w3m-button': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;  
    }  
  }  
}