import React from 'react';
import styled from 'styled-components';
import { WebConfigV2 } from "../../../../config/web.config.v2";
import { showProcessingToast, updateErrorToast, updateSuccessToast } from "../../../../helper/ToastHelper";
import { Web3ServiceIns } from "../../../../service/Web3Service";
import { BREAK_POINT_MEDIUM, BREAK_POINT_SMALL } from "../../../constants/constants";

const TokenInfoSection: React.FC = () => {

  const handleAddMetamask = async () => {
    showProcessingToast('add_metamask', 'Adding token to metamask...');
    try {
      const result = await Web3ServiceIns.addMetamask();
      if (result) {
        updateSuccessToast('add_metamask', 'Successfully added metamask.');
      } else {
        updateErrorToast('add_metamask', 'Failed to add metamask.');
      }
    } catch (e) {
      updateErrorToast('add_metamask', 'Failed to add metamask.');
    }
  }

  return (
    <Section>
      <Title>{WebConfigV2.Content.token_info_section.title}</Title>
      <Container>
        {
          WebConfigV2.Content.token_info_section.infos.map(info => (
            <InfoCard>
              <Label>{info.title}</Label>
              <Value fontSize={info.highlight ? '32px' : '24px'}>{info.desc}</Value>
            </InfoCard>
          ))
        }
      </Container>

      <MetamaskButton onClick={handleAddMetamask}>
        <MetamaskIcon src="metamask.webp" />
        Add to MetaMask
      </MetamaskButton>
    </Section>
  );
};

const Section = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding-bottom: 48px;
  position: relative;
  padding: 0 8%;

  &::before {
    content: '';
    position: absolute;
    top: -10%;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(circle at 50% 0%, rgba(167, 139, 250, 0.1), transparent 50%);
    pointer-events: none;
  }
`;

const Title = styled.h2`
  font-size: 64px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 80px;
  background: linear-gradient(to bottom, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 
    0 0 7px rgba(255, 215, 0, 0.3),
    0 0 10px rgba(255, 215, 0, 0.2);

  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 48px;
    margin-bottom: 60px;
  }
`;

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const InfoCard = styled.div`
  background: rgba(0, 0, 0, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  padding: 16px;
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba(255, 215, 0, 0.3);
    box-shadow: 0 0 20px rgba(255, 215, 0, 0.1);
  }
`;

const Label = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 8px;
  background: linear-gradient(to right, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 0 10px rgba(255, 215, 0, 0.2);
`;

interface ValueProps {
  fontSize?: string;
}

const Value = styled.div<ValueProps>`
  font-size: 18px;
  font-weight: 600;
  color: #a78bfa; /* Màu tím sáng */
  text-shadow: 0 0 10px rgba(167, 139, 250, 0.3);
  word-break: break-all;
`;

const MetamaskButton = styled.button`  
  display: flex;  
  align-items: center;  
  gap: 12px;  
  background: linear-gradient(to top, #FFD700, #FFED4A);
  position: relative;  
  border: none;  
  border-radius: 0.75rem;  
  padding: 16px 32px;  
  color: #000;
  font-size: 18px;  
  font-weight: 600;  
  cursor: pointer;  
  transition: all 0.3s ease;  
  
  &::before {  
    content: '';  
    position: absolute;  
    top: 0;  
    left: 0;  
    right: 0;  
    bottom: 0;  
    border-radius: 0.75rem;  
    padding: 1px;
    background: linear-gradient(180deg,   
      rgba(255, 215, 0, 0.3) 0%,
      rgba(255, 215, 0, 0.1) 30%,  
      transparent 100%  
    );  
    -webkit-mask:   
      linear-gradient(#fff 0 0) content-box,   
      linear-gradient(#fff 0 0);  
    -webkit-mask-composite: xor;  
    mask-composite: exclude;  
    pointer-events: none;  
  }  
  
  &:hover {
    transform: translateY(-2px);
    box-shadow: 
      0 0 7px #FFD700,
      0 0 10px #FFD700,
      0 0 21px #FFD700,
      0 0 42px rgb(255, 215, 0);
  } 
  
  &:active {  
    transform: translateY(0);  
  }  

  &:disabled {  
    background: rgba(255, 255, 255, 0.1);  
    cursor: not-allowed;  
    transform: none;  
    box-shadow: none;  
    
    &::before {  
      background: transparent;  
    }  
  }  
`;

const MetamaskIcon = styled.img`
  width: 32px;
  height: 32px;
  object-fit: contain;
  object-position: center;
  @media (max-width: 500px) {
    width: 24px;
    height: 24px;
  }
`;

export default TokenInfoSection;