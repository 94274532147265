import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {AppProvider} from "./context/AppContext";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

window.onerror = function(message, source, lineno, colno, error) {
  console.warn('Error caught:', { message, source, lineno, colno, error });
  return true;
};

window.addEventListener('error', function(event) {
  event.preventDefault();
  return false;
});

root.render(
  <AppProvider>
    <App />
  </AppProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
