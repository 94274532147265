import { id } from 'ethers';
import React from 'react';
import styled, { keyframes, css } from 'styled-components';
import { WebConfigV2 } from '../../../../config/web.config.v2';
import { BREAK_POINT_MEDIUM } from '../../../constants/constants';
import { SectionTitle } from '../styles/CommonStyles';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
}

const PageContainer = styled.div`  
  padding: 2rem;  
  color: white;  
  position: relative;  
`;

const HeaderSection = styled.div`  
  position: relative;  
  padding: 2rem 0;  
  margin-bottom: 2rem;  

  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    margin-bottom: 0;  
  };
  
  &::before {  
    content: '';  
    position: absolute;  
    top: 0;  
    left: 0;  
    right: 0;  
    height: 100%;  
    background: radial-gradient(  
      circle at center,  
      rgba(255, 255, 255, 0.05) 0%,  
      rgba(255, 255, 255, 0.02) 40%,  
      transparent 70%  
    );  
    backdrop-filter: blur(60px);  
    -webkit-backdrop-filter: blur(60px);  
    z-index: 1;  
  }  
`;

const ContentWrapper = styled.div`  
  max-width: 80rem;  
  margin: 0 auto;  
  position: relative;  
  z-index: 2;  
`;

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  
  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const FeatureCard = styled.div`
  background-color: #1a2234;
  border-radius: 0.75rem;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  border: 1px solid #2a3441;
  transition: all 0.3s ease;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(99, 102, 241, 0.2);
  }
`;

const IconContainer = styled.div`
  width: 4rem;
  height: 4rem;
  background: linear-gradient(to bottom right, #FFD700, #FFA500);
  border-radius: 0.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 15px rgba(255, 215, 0, 0.3);
  
  svg {
    width: 2.5rem;
    height: 2.5rem;
    stroke: #FFFFFF;
    stroke-width: 1.5;
    filter: drop-shadow(0 0 2px rgba(255, 255, 255, 0.5));
  }
`;

const TextContainer = styled.div`
  flex: 1;
`;

const CardTitle = styled.h3`
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
  color: #e2e8f0;
`;

const CardDescription = styled.p`
  color: #a78bfa;
  font-size: 16px;
  line-height: 1.5;
  text-shadow: 0 0 10px rgba(167, 139, 250, 0.2);
  transition: all 0.3s ease;

  ${FeatureCard}:hover & {
    text-shadow: 0 0 15px rgba(167, 139, 250, 0.3);
  }
`;

const FeatureCardComponent: React.FC<FeatureCardProps> = ({ icon, title, description }) => (
  <FeatureCard>
    <IconContainer>
      {icon}
    </IconContainer>
    <TextContainer>
      <CardTitle>{title}</CardTitle>
      <CardDescription>{description}</CardDescription>
    </TextContainer>
  </FeatureCard>
);

const KeyFeatureSection: React.FC<{ id: string }> = ({ id }) => {
  const features = [
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[0].title,
      description: WebConfigV2.Content.key_feature_section.features[0].description
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[1].title,
      description: WebConfigV2.Content.key_feature_section.features[1].description
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[2].title,
      description: WebConfigV2.Content.key_feature_section.features[2].description
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[3].title,
      description: WebConfigV2.Content.key_feature_section.features[3].description
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[4].title,
      description: WebConfigV2.Content.key_feature_section.features[4].description
    },
    {
      icon: (
        <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6" />
        </svg>
      ),
      title: WebConfigV2.Content.key_feature_section.features[5].title,
      description: WebConfigV2.Content.key_feature_section.features[5].description
    }
  ];

  return (
    <PageContainer id={id}>
      <ContentWrapper>
        <HeaderSection>
          <ContentWrapper>
            <SectionTitle>Key Features of BNB Staking</SectionTitle>
          </ContentWrapper>
        </HeaderSection>
        <GridContainer>
          <Column>
            {features.slice(0, 3).map((feature, index) => (
              <FeatureCardComponent
                key={index}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </Column>
          <Column>
            {features.slice(3).map((feature, index) => (
              <FeatureCardComponent
                key={index + 3}
                icon={feature.icon}
                title={feature.title}
                description={feature.description}
              />
            ))}
          </Column>
        </GridContainer>
      </ContentWrapper>
    </PageContainer>
  );
};

export default KeyFeatureSection;