import React from 'react';
import styled from 'styled-components';
import {BREAK_POINT_MEDIUM} from "../../../constants/constants";
import {WebConfigV2} from "../../../../config/web.config.v2";
import { SectionTitle } from '../styles/CommonStyles';

const Section = styled.section`
  width: 100%;
  margin-bottom: 100px;
  padding: 0 8%;
`;

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  z-index: 1;
`;

const Title = styled.h2`
  font-size: 64px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 80px;

  background-image: linear-gradient(to right, #e5e7eb, #c7d2fe, #f9fafb, #a5b4fc, #e5e7eb);
  background-size: 200% auto;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: shine 6s cubic-bezier(0.39, 0.575, 0.565, 1) infinite;  

  @keyframes shine {  
    from {  
      background-position: -100% center;  
    }  
    to {  
      background-position: 100% center;  
    }  
  } 

  @media (max-width: ${() => `${BREAK_POINT_MEDIUM}px`}) {
    font-size: 48px;
    margin-bottom: 60px;
  };
`;

const PartnersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;

const PartnerCard = styled.a`
  background: rgba(255, 255, 255, 0.03);
  border: 1px solid rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.03) 0%,
      transparent 70%
    );
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    transform: translateY(-4px);
    border-color: rgba(255, 255, 255, 0.1);
    background: rgba(255, 255, 255, 0.05);
    
    &::before {
      opacity: 1;
    }

    img {
      filter: brightness(1.2);
    }
  }
`;

const GradientOverlay = styled.img`  
  position: absolute;  
  right: 10%;  
  z-index: 0;  
  bottom: 0;
  pointer-events: none;
`;  

const PartnerLogo = styled.img`
  height: 90%;
  object-fit: contain;
  transition: all 0.3s ease-in-out;
  filter: brightness(0.9);
`;

const PartnerSectionV2: React.FC = () => {
  return (
    <Section>
      <SectionTitle>ExtremeBTC trading market</SectionTitle>
      <Container>
        <PartnersGrid>
          {WebConfigV2.Content.partner_section.partners.map((partner) => (
            <PartnerCard
              key={partner.name}
              href={partner.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <PartnerLogo
                src={partner.logo}
                alt={`${partner.name} logo`}
              />
            </PartnerCard>
          ))}
        </PartnersGrid>
      </Container>
      <GradientOverlay src="./images/footer-illustration.svg" />  
    </Section>
  );
};

export default PartnerSectionV2;