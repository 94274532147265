  import { useEffect } from 'react';

const useSmoothScroll = () => {
  useEffect(() => {
    const handleAnchorClick = (event: MouseEvent) => {
      const target = event.target as HTMLAnchorElement;
      const anchor = target.closest('a');
      if (target.tagName === 'A' || (anchor && anchor.tagName === 'A')) {
        event.preventDefault();
        const targetId = target.tagName === 'A' ? target.getAttribute('href') : (anchor ? anchor.getAttribute('href') : '');
        if (targetId && !targetId.startsWith("#")) {
          window.open(targetId, '_blank');
          return
        }
        
        const targetElement = document.querySelector(targetId || '');
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
          window.history.pushState(null, '', targetId);
        }
      }
    };
    
    document.addEventListener('click', handleAnchorClick);
    return () => {
      document.removeEventListener('click', handleAnchorClick);
    };
  }, []);
};

export default useSmoothScroll;