import React, {createContext, useState} from "react";
import {TokenStatistic} from "../models/TokenStatistic";
import {MinningInfo} from "../models/MinningInfo";
import {RefInfo} from "../models/RefInfo";

export interface AppContextType {
  walletAddress: string;
  setWalletAddress: React.Dispatch<React.SetStateAction<string>>;
  
  walletBalance: number;
  setWalletBalance: React.Dispatch<React.SetStateAction<number>>;
  
  tokenStatistic: TokenStatistic;
  setTokenStatistic: React.Dispatch<React.SetStateAction<TokenStatistic>>;
  
  minningInfo: MinningInfo;
  setMinningInfo: React.Dispatch<React.SetStateAction<MinningInfo>>;
  
  refInfo: RefInfo;
  setRefInfo: React.Dispatch<React.SetStateAction<RefInfo>>;
}

export const AppContext = createContext<AppContextType | undefined>(undefined);
export const AppProvider: React.FC<{ children: React.ReactNode }> = ({children}) => {
  const [walletAddress, setWalletAddress] = useState<string>("");
  const [walletBalance, setWalletBalance] = useState<number>(0);
  const [tokenStatistic, setTokenStatistic] = useState<TokenStatistic>({
    priceBNB: '0.00',
    priceUSD: '0.00',
    totalSupply: '0.00',
    circulatingSupply: '0.00',
    availableSupply: '0.00',
    totalStakedBNB: '0.00',
    totalStakedUSD: '0.00',
    totalLoans: '0.00',
    totalLoansUSD: '0.00',
    tvlBNB: '0.00',
    tvlUSD: '0.00',
  });
  const [minningInfo, setMinningInfo] = useState<MinningInfo>({
    arp_m: '0.00',
    arp_t: '0.00',
    totalStaked_m: '0.00',
    totalStaked_t: '0.00',
    userStaked_m: '0.00',
    userStaked_t: '0.00',
    userMinned_m: '0.00',
    userMinned_t: '0.00',
    userBalance: '0.00',
    userTokenBalance: '0.00',
    userBalanceWei: BigInt(0),
    userTokenBalanceWei: BigInt(0),
    soldToday: '0.0000',
    availableToday: '0.0000',
    timeReset: 0,
    timeStopLoan: 0,
    startTS: 0,
    endTS: 0,
    stakeDay: 0,
    totalDay: 0,
    extendPkgs: []
  });
  const [refInfo, setRefInfo] = useState<RefInfo>({
    earnAvailable: '0.0000',
    totalEarned: '0.0000',
    totalWithdraw: '0.0000',
    totalReferral: 0
  });
  
  return (
    <AppContext.Provider value={{
      walletAddress,
      setWalletAddress,
      
      walletBalance,
      setWalletBalance,
      
      tokenStatistic,
      setTokenStatistic,
      
      minningInfo,
      setMinningInfo,
      
      refInfo,
      setRefInfo,
    }}>
      {children}
    </AppContext.Provider>
  );
}
