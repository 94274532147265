import React, {useState} from "react";
import styled, {css, keyframes} from "styled-components";
import {WebConfigV2} from "../../../../config/web.config.v2";

export const RightBar: React.FC<{
  onHide: () => void;
}> = ({
        onHide
      }) => {
  
  const [isVisible, setIsVisible] = useState<boolean>(true);
  
  const handleOnClose = () => {
    setIsVisible(false);
    setTimeout(() => {
      if (onHide) {
        onHide();
      }
    }, 300);
  }
  
  return (
    <Container>
      <DismissBackdrop onClick={handleOnClose}>
      </DismissBackdrop>
      <RightBarContainer is_visible={isVisible ? 1 : 0}>
        <a href='#home' onClick={handleOnClose}>
          <RightBarItemTitle>{WebConfigV2.Content.nav_section.home_title}</RightBarItemTitle>
        </a>
        <SeparatorLine/>
        <a href='#action' onClick={handleOnClose}>
          <RightBarItemTitle>{WebConfigV2.Content.nav_section.stake_title}</RightBarItemTitle>
        </a>
        <SeparatorLine/>
        <a href='referral' onClick={handleOnClose}>
          <RightBarItemTitle>{WebConfigV2.Content.nav_section.referral_title}</RightBarItemTitle>
        </a>
        <SeparatorLine/>
        <a href={WebConfigV2.Content.nav_section.document_link} onClick={handleOnClose}>
          <RightBarItemTitle>{WebConfigV2.Content.nav_section.document_title}</RightBarItemTitle>
        </a>
      </RightBarContainer>
    </Container>
  )
}

const AppearAnimation = keyframes`
  from {
    transform: translateX(300px);
  }
  to {
    transform: translateX(0);
  }
`;

const DisappearAnimation = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(300px);
  }
`

const Container = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
`

const DismissBackdrop = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`

const RightBarContainer = styled.div<{is_visible: number}>`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  padding: 1rem;
  gap: 1rem;
  background-color: #000;
  animation: ${({is_visible}) =>
  is_visible
    ? css`${AppearAnimation} 0.3s ease-in-out forwards`
    : css`${DisappearAnimation} 0.3s ease-in-out forwards`
};
`

const RightBarItemTitle = styled.span`
  font-size: .95rem;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
  line-height: 1.5;
  color: #fff;
`

const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #fff;
`

