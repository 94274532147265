import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { StakeComponent } from "./ActionSectionV2/StakeComponent";
import { LoanComponent } from "./ActionSectionV2/LoanComponent";
import { SellComponent } from "./ActionSectionV2/SellComponent";
import {BREAK_POINT_MEDIUM, BREAK_POINT_SMALL} from "../../../constants/constants";
import {WebConfigV2} from "../../../../config/web.config.v2";
import { SectionTitle } from '../styles/CommonStyles';

const ActionSection: React.FC<{ id: string }> = ({ id }) => {
  const [activeTab, setActiveTab] = useState('Stake');
  const [showTooltip, setShowTooltip] = useState('');
  
  const tabInfos = WebConfigV2.Content.action_section.tabbar;
  
  return (
    <Section id={id}>
      <SectionTitle> Quick Node Web3 APIs</SectionTitle>
      <BackgroundCoins>
        <Coin
          size="60px"
          top="15%"
          left="15%"
          animationType="float"
          duration="4s"
          delay="0s"
        />
        <Coin
          size="40px"
          top="75%"
          left="10%"
          animationType="floatReverse"
          duration="5s"
          delay="0.5s"
        />
        <Coin
          size="50px"
          top="25%"
          left="85%"
          animationType="floatReverse"
          duration="4.5s"
          delay="1s"
        />
        <Coin
          size="45px"
          top="65%"
          left="85%"
          animationType="float"
          duration="5.5s"
          delay="0.7s"
        />
        <Coin
          size="35px"
          top="45%"
          left="5%"
          animationType="pulse"
          duration="4s"
          delay="1.5s"
        />
        <Coin
          size="30px"
          top="35%"
          left="92%"
          animationType="pulse"
          duration="4.2s"
          delay="0.3s"
        />
      </BackgroundCoins>
      <GlowEffect />
      
      <Container>
        <TabBar>
          {tabInfos.map(tabInfo => (
            <TabButton
              key={tabInfo.tab_title}
              active={activeTab === tabInfo.tab_title}
              onClick={() => setActiveTab(tabInfo.tab_title)}
            >
              {tabInfo.tab_title}
              <InfoIcon
                onMouseEnter={() => setShowTooltip(tabInfo.tab_title)}
                onMouseLeave={() => setShowTooltip('')}
              >
                <img width='100%' src='ic_info.svg' />
                {showTooltip === tabInfo.tab_title && <Tooltip>{tabInfo.help}</Tooltip>}
              </InfoIcon>
            </TabButton>
          ))}
        </TabBar>
        
        {activeTab === tabInfos[0].tab_title && <StakeComponent/>}
        {activeTab === tabInfos[1].tab_title && <LoanComponent/>}
        {activeTab === tabInfos[2].tab_title && <SellComponent/>}
      </Container>
    </Section>
  );
};

const float = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-20px) rotate(6deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

const floatReverse = keyframes`
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  50% {
    transform: translateY(-30px) rotate(-8deg);
  }
  100% {
    transform: translateY(0px) rotate(0deg);
  }
`;

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 0.8;
  }
  50% {
    transform: scale(1.1);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.8;
  }
`;

interface CoinProps {
  delay: string;
  duration: string;
  size: string;
  top: string;
  left: string;
  animationType: 'float' | 'floatReverse' | 'pulse';
}

const getCoinAnimation = (type: 'float' | 'floatReverse' | 'pulse') => {
  switch (type) {
    case 'float':
      return float;
    case 'floatReverse':
      return floatReverse;
    case 'pulse':
      return pulse;
    default:
      return float;
  }
};

const Coin = styled.div<CoinProps>`
  position: absolute;
  width: ${props => props.size};
  height: ${props => props.size};
  top: ${props => props.top};
  left: ${props => props.left};
  background: linear-gradient(45deg, #F3BA2F, #FFCE54);
  border-radius: 50%;
  animation: ${props => css`${getCoinAnimation(props.animationType)} ${props.duration} ease-in-out infinite`};
  animation-delay: ${props => props.delay};
  opacity: 0.15;
  box-shadow: 0 0 30px rgba(243, 186, 47, 0.3);

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: url("bnb.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    opacity: 0.8;
  }
`;

const Section = styled.section`
  width: 100%;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  padding: 4% 8%;
`;

const Container = styled.div`
  max-width: 600px;
  margin: 0 auto;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 24px;
  padding: 48px 32px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  z-index: 1;
  backdrop-filter: blur(10px);
  @media (max-width: ${() => `${BREAK_POINT_SMALL}px`}) {
    padding: 24px 16px;
  }
`;

const TabBar = styled.div`
  display: flex;
  gap: 2px;
  background: rgba(255, 255, 255, 0.05);
  padding: 4px;
  border-radius: 16px;
  margin-bottom: 32px;
`;

const TabButton = styled.button<{ active: boolean }>`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  
  background: ${({ active }) => 
    active 
      ? 'linear-gradient(to top, #FFD700, #FFED4A)'
      : 'rgba(255, 255, 255, 0.1)'
  };
  color: ${({ active }) => active ? '#000' : '#fff'};
  padding: 12px;
  border-radius: 12px;
  border: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: -1px;
    border-radius: 12px;
    padding: 1px;
    background: ${({ active }) =>
      active
        ? 'linear-gradient(135deg, rgba(255, 215, 0, 0.3), rgba(255, 215, 0, 0.1))'
        : 'linear-gradient(135deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.05))'
    };
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    pointer-events: none;
  }

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${({ active }) =>
      active
        ? '0 0 7px #FFD700, 0 0 10px #FFD700, 0 0 21px #FFD700'
        : '0 5px 15px rgba(255, 255, 255, 0.1)'
    };
  }

  &:active {
    transform: translateY(0);
  }
`;

const InfoIcon = styled.div`
  width: 16px;
  height: 16px;
  position: relative;
  cursor: help;
`;

const Tooltip = styled.span`
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: 350px;
  transform: translateX(-50%);
  background: rgba(0, 0, 0, 0.9);
  padding: 8px 12px;
  border-radius: 8px;
  font-size: 12px;
  color: white;
  pointer-events: none;
  margin-bottom: 8px;
  z-index: 10;
  white-space: pre-line;

  &::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 4px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.9) transparent transparent transparent;
  }
`;

const BackgroundCoins = styled.div`
  position: absolute;
  top: 0;
  left: 8%;
  width: 84%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
`;

const GlowEffect = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background: radial-gradient(
    circle at center,
    rgba(243, 186, 47, 0.1) 0%,
    transparent 70%
  );
  pointer-events: none;
`;

export default ActionSection;